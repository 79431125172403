import {
  Fingerprint as FingerprintIcon,
  FlashOn as FlashOnIcon,
  Psychology as PsychologyIcon,
  School as SchoolIcon,
} from "@mui/icons-material";

export default [
  {
    href: "/contact",
    icon: FingerprintIcon,
    subheader: "Responding to the aftermath of cyber attacks and breaches.",
    title: "Digital Forensics and Incident Response",
  },
  {
    href: "/contact",
    icon: FlashOnIcon,
    subheader:
      "From the wider Internet right to the heart of your organisation.",
    title: "Red Teaming | Adversarial Attack Simulation",
  },
  {
    href: "/contact",
    icon: PsychologyIcon,
    subheader:
      "Manipulating and exploiting people to get into your organisation.",
    title: "Social Engineering",
  },
  {
    href: "/contact",
    icon: SchoolIcon,
    subheader: "Putting cyber security in your hands.",
    title: "Training | Education",
  },
];
