import {
  Mail as MailIcon,
  Phone as PhoneIcon,
  PinDrop as PinDropIcon,
} from "@mui/icons-material";

import { France, UK } from "../assets/optimized-svgs";

export default [
  {
    children: [
      {
        href: "//www.google.com/maps/place/71+Shelton+St,+London+WC2H+9BP",
        icon: PinDropIcon,
        label: `71-75 Shelton Street
          Covent Garden
          London, WC2H 9JQ
          United Kingdom`,
        type: "Address",
      },
      {
        href: "mailto:sales@aegiscyber.co.uk",
        icon: MailIcon,
        label: "sales@aegiscyber.co.uk",
        type: "Email",
      },
      {
        href: "tel:+447999900908",
        icon: PhoneIcon,
        label: "+44 7999 900908",
        type: "Phone",
      },
    ],
    icon: UK,
    label: "London, UK (HQ)",
  },
  {
    children: [
      {
        href: "",
        icon: PinDropIcon,
        label: "Toulouse, 31000, France",
        type: "Address",
      },
      {
        href: "mailto:sales@aegiscyber.co.uk",
        icon: MailIcon,
        label: "sales@aegiscyber.co.uk",
        type: "Email",
      },
    ],
    icon: France,
    label: "Toulouse, France",
  },
];
