import { CISSP, CREST, OffSec, SANS } from "../../assets/optimized-svgs";

export default [
  {
    label: "CREST",
    svg: CREST,
  },
  {
    label: "ISC2",
    svg: CISSP,
  },
  {
    label: "OffSec",
    svg: OffSec,
  },
  {
    label: "SANS",
    svg: SANS,
  },
];
