import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { RouterProvider } from "react-router-dom";

import { routerConfig } from "./configs";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
  debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
  testMode: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={routerConfig} />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// Unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
