import "mapbox-gl/dist/mapbox-gl.css";

import {
  Avatar,
  Box,
  Grid2,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import mapboxgl from "mapbox-gl";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { uid } from "react-uid";

import { FormContact } from "../../components";
import { contacts } from "../../models";

const ContactDetailsSection = () => (
  <>
    <Typography gutterBottom sx={{ fontWeight: "bold" }} variant="h3">
      Contact
    </Typography>
    <Typography sx={{ color: "text.secondary", mb: 2 }}>
      Please provide your details and reason for getting in touch and we will
      reply as soon as possible.
    </Typography>
    <Typography sx={{ color: "text.secondary", fontWeight: "bold", mb: 2 }}>
      You can also call our offices to speak directly with our experts.
    </Typography>
    <FormContact />
  </>
);

const ContactItem = ({ nestedItem }) => (
  <ListItem
    key={uid(nestedItem)}
    disablePadding
    component={motion.div}
    transition={{ bounce: 0.25, duration: 0.5, type: "spring" }}
    whileHover={{ scale: 1.05 }}
  >
    <ListItemButton
      component="a"
      href={nestedItem.href}
      {...(nestedItem.type === "Address" && {
        rel: "noopener noreferrer",
        target: "_blank",
      })}
      sx={{
        borderRadius: 2,
        height: "100%",
      }}
    >
      <ListItemAvatar>
        <Avatar>
          <nestedItem.icon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={nestedItem.type}
        secondary={nestedItem.label}
        sx={{
          whiteSpace: "pre-line",
        }}
      />
    </ListItemButton>
  </ListItem>
);

ContactItem.propTypes = {
  nestedItem: PropTypes.shape({
    href: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

const MapSection = ({ mapContainer, matches, theme }) => {
  const map = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_GL;
    const center = [-0.1228086697089611, 51.51506682781911];

    const initializeMap = () => {
      map.current = new mapboxgl.Map({
        center,
        container: mapContainer.current,
        style:
          theme.palette.mode === "light"
            ? "mapbox://styles/mapbox/streets-v11"
            : "mapbox://styles/mapbox/dark-v10",
        zoom: 11,
      });
      new mapboxgl.Marker().setLngLat(center).addTo(map.current);
    };

    if (map.current) {
      map.current.setStyle(
        theme.palette.mode === "light"
          ? "mapbox://styles/mapbox/streets-v11"
          : "mapbox://styles/mapbox/dark-v10",
      );
    } else {
      initializeMap();
    }

    new mapboxgl.Marker().setLngLat(center).addTo(map.current);
  }, [mapContainer, theme.palette.mode]);

  return (
    <Box
      ref={mapContainer}
      className="map-container"
      sx={
        matches
          ? {
              height: "100%",
              left: "0px",
              position: "absolute",
              top: "0px",
              width: "calc(50vw - 16px)",
            }
          : {
              height: "100%",
              minHeight: 256,
              width: "100%",
            }
      }
    />
  );
};

MapSection.propTypes = {
  mapContainer: PropTypes.shape({ current: PropTypes.object }),
  matches: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};

const ContactView = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const mapContainer = useRef(null);

  return (
    <div className="outer">
      <div className="divider-bottom inner">
        <Grid2 container direction="row-reverse" spacing={2}>
          <Grid2
            size={{ sm: 6, xs: 12 }}
            sx={{
              position: "relative",
            }}
          >
            <MapSection
              mapContainer={mapContainer}
              matches={matches}
              theme={theme}
            />
          </Grid2>
          <Grid2
            size={{ sm: 6, xs: 12 }}
            sx={{
              padding: { sm: "64px 16px", xs: 0 },
            }}
          >
            <ContactDetailsSection />
          </Grid2>
        </Grid2>
      </div>

      <div className="padding-half">
        <Stack
          direction={matches ? "row" : "column"}
          spacing={2}
          sx={{
            justifyContent: "center",
          }}
        >
          {contacts
            .filter((item) => item.label === "London, UK (HQ)")
            .map((filteredItem) =>
              filteredItem.children.map((nestedItem) => (
                <ContactItem key={uid(nestedItem)} nestedItem={nestedItem} />
              )),
            )}
        </Stack>
      </div>
    </div>
  );
};

export default ContactView;
