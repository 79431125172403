import React from "react";
import { createBrowserRouter } from "react-router-dom";

import App from "../../App";
import { notFoundRoute, publicRoutes } from "./publicRoutes";

const routerConfig = createBrowserRouter([
  {
    children: [...publicRoutes, notFoundRoute],
    element: <App />,
    path: "/",
  },
]);

export default routerConfig;
