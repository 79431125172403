import {
  alpha,
  Box,
  Container,
  CssBaseline,
  Link,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import React, { useRef, useState } from "react";
import { Outlet, ScrollRestoration, useNavigation } from "react-router-dom";

import { getDesignTokens, getThemedComponents } from "./assets";
import {
  CookieConsent,
  CustomHelmet,
  Drawer,
  Footer,
  Header,
  LoadingSpinner,
} from "./components";
import { useGoogleAnalytics, useResizeObserver } from "./hooks";

const StyledBox = styled(Box)(({ theme }) => ({
  "& .divider-bottom": { borderBottom: `1px solid ${theme.palette.divider}` },

  "& .divider-svg": { position: "relative" },

  "& .front": {
    position: "relative",
    zIndex: 1,
  },

  "& .padding-hero": {
    margin: "auto",
    maxWidth: theme.breakpoints.values["lg"],
    padding: "7.5vh 16px",
  },

  "& .slick-dots > li > button::before": {
    color: theme.palette.text.secondary,
  },

  "& .slick-dots > li.slick-active > button::before, & .slick-prev::before, & .slick-next::before":
    { color: theme.palette.text.primary },

  "& .text-secondary-on-primary-background": {
    color: alpha(theme.palette.text.secondary, 0.74),
    ...theme.applyStyles("dark", {
      color: alpha(theme.palette.text.secondary, 0.94),
    }),
    fontWeight: 550,
  },

  "& > div.background-primary": {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "none !important",
  },

  "& > div.outer > div.inner": {
    margin: "auto",
    maxWidth: theme.breakpoints.values["lg"],
    padding: `5vh ${theme.spacing(2)}`,
  },

  "& > div.outer > div.padding-half": {
    margin: "auto",
    maxWidth: theme.breakpoints.values["lg"],
    padding: `2.5vh ${theme.spacing(2)}`,
  },

  "& > div:not(.background-default), & div.background-gradient": {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.default}, ${theme.palette.background.darker})`,
  },

  "& img": theme.applyStyles("dark", { filter: "brightness(0.75)" }),

  '& svg[id^="background-default"]': {
    path: { fill: theme.palette.background.default },
  },

  '& svg[id^="background-primary"]': {
    path: { fill: theme.palette.primary.main },
  },

  '& svg[id^="elevation-4"]': {
    path: {
      fill: theme.palette.background.default,
      ...theme.applyStyles("dark", {
        fill: "#272727",
      }),
    },
  },
}));

const StyledFooter = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: "5vh",
}));

const App = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const { state = "loading" } = useNavigation();

  const controlBarRef = useRef(null);
  const controlBarHeight = useResizeObserver(controlBarRef);
  const appBarRef = useRef(null);
  const appBarHeight = useResizeObserver(appBarRef);

  useGoogleAnalytics();

  const theme = responsiveFontSizes(
    createTheme({
      ...getDesignTokens(),
      // TODO: https://github.com/mui/material-ui/issues/44214
      ...getThemedComponents(),
    })
  );

  const renderContent = () => {
    const minHeight = `calc(100vh - ${controlBarHeight + appBarHeight}px)`;

    return state === "loading" ? (
      <LoadingSpinner sx={{ minHeight }} />
    ) : (
      <Outlet
        context={{
          appBarHeight,
          controlBarHeight,
          sx: { minHeight },
        }}
      />
    );
  };

  return (
    <ThemeProvider
      theme={theme}
      defaultMode={prefersDarkMode ? "dark" : "light"}
    >
      <CustomHelmet theme={theme} />
      <CssBaseline enableColorScheme />
      <SnackbarProvider
        preventDuplicate
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        maxSnack={3}
      >
        <Header
          appBarRef={appBarRef}
          controlBarHeight={controlBarHeight}
          controlBarRef={controlBarRef}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
        <Drawer open={drawerOpen} setOpen={setDrawerOpen} />
        <StyledBox>
          <ScrollRestoration />
          {renderContent()}
        </StyledBox>
        <CookieConsent
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
          }}
          buttonLabel="Consent"
          message={
            <Typography sx={{ mb: 2 }}>
              We use cookies to ensure that we give you the best experience on
              our website. Read our{" "}
              <Link
                href="/legal/privacy-policy"
                sx={(theme) =>
                  theme.applyStyles("light", {
                    color: theme.palette.primary.light,
                  })
                }
              >
                Privacy Policy
              </Link>
              .
            </Typography>
          }
        />
        <StyledFooter>
          <Container maxWidth="md">
            <Footer />
          </Container>
        </StyledFooter>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
