import { useEffect, useState } from "react";

const useResizeObserver = (targetRef) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (targetRef.current) setHeight(targetRef.current.offsetHeight);
    };

    window.addEventListener("resize", updateHeight);

    updateHeight();

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [targetRef]);

  return height;
};

export default useResizeObserver;
