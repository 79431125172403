import {
  AlexandreTeyar,
  BloodHound,
  Excel,
  GoogleAuth,
  JWT,
  OpenAPI,
  Phishing,
  PowerShell,
  SCI,
  SMB,
  SolsticePod,
  WireSpy,
  XMLRPC,
} from "../assets/images";
// Import { post0 } from './resources';

export default [
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["0-days"],
    content: ``,
    date: "04 March 2022",
    excerpt: `CVE-2017-12945`,
    href: "//github.com/aress31/cve-2017-12945",
    id: 0,
    imgAlt: "CVE-2017-12945",
    imgSrc: SolsticePod,
    title: "Solstice Pod (Mersive Technologies) Vulnerability",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["0-days"],
    content: ``,
    date: "04 March 2022",
    excerpt: `CVE-2020-35584`,
    href: "//github.com/aress31/solstice-pod-cves",
    id: 1,
    imgAlt: "CVE-2020-35584",
    imgSrc: SolsticePod,
    title: "Solstice Pod (Mersive Technologies) Vulnerability",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["0-days"],
    content: ``,
    date: "04 March 2022",
    excerpt: `CVE-2020-35585`,
    href: "//github.com/aress31/solstice-pod-cves",
    id: 2,
    imgAlt: "CVE-2020-35585",
    imgSrc: SolsticePod,
    title: "Solstice Pod (Mersive Technologies) Vulnerability",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["0-days"],
    content: ``,
    date: "04 March 2022",
    excerpt: `CVE-2020-35586`,
    href: "//github.com/aress31/solstice-pod-cves",
    id: 3,
    imgAlt: "CVE-2020-35586",
    imgSrc: SolsticePod,
    title: "Solstice Pod (Mersive Technologies) Vulnerability",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["0-days"],
    content: ``,
    date: "04 March 2022",
    excerpt: `CVE-2020-35587`,
    href: "//github.com/aress31/solstice-pod-cves",
    id: 4,
    imgAlt: "CVE-2020-35587",
    imgSrc: SolsticePod,
    title: "Solstice Pod (Mersive Technologies) Vulnerability",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["Tools"],
    content: ``,
    date: "04 March 2022",
    excerpt: `A Burp Suite extension designed to automate and streamline (OpenAPI-based) API testing.`,
    href: "//github.com/aress31/swurg",
    id: 5,
    imgAlt: "OpenAPI Parser (Burp Suite Extension)",
    imgSrc: OpenAPI,
    title: "OpenAPI Parser (Burp Suite Extension)",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["Tools"],
    content: ``,
    date: "04 March 2022",
    excerpt: `Generate charts/statistics to visualise the outcome of phishing engagements.`,
    href: "//github.com/aress31/phishstat",
    id: 6,
    imgAlt: "Phishstat",
    imgSrc: Phishing,
    title: "Phishstat",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["Tools"],
    content: ``,
    date: "04 March 2022",
    excerpt: `Enables the automation of various WiFi attacks to conduct Man-In-The-Middle-Attacks (MITMAs).`,
    href: "//github.com/aress31/wirespy",
    id: 7,
    imgAlt: "Wirespy",
    imgSrc: WireSpy,
    title: "Wirespy",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["Tools"],
    content: ``,
    date: "04 March 2022",
    excerpt: `A CPU-based JSON Web Token (JWT) cracker and - to some extent - scanner.`,
    href: "//github.com/aress31/jwtcat",
    id: 8,
    imgAlt: "JWTCat",
    imgSrc: JWT,
    title: "JWTCat",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["Tools"],
    content: ``,
    date: "04 March 2022",
    excerpt: `Bruteforces CMS users' passwords leveraging the "Brute Force Amplification Attack" exploit against XMLRPC interfaces enabling the system.multicall() method (enabled by default).`,
    href: "//github.com/aress31/xmlrpc-bruteforcer",
    id: 9,
    imgAlt: "xmlrpc-bruteforcer",
    imgSrc: XMLRPC,
    title: "xmlrpc-bruteforcer",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["Tools"],
    content: ``,
    date: "04 March 2022",
    excerpt: `Automates assembly code (smali) injection within Android applications.`,
    href: "//github.com/aress31/sci",
    id: 10,
    imgAlt: "Smali Code Injector (SCI)",
    imgSrc: SCI,
    title: "Smali Code Injector (SCI)",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["Tools"],
    content: ``,
    date: "04 March 2022",
    excerpt: `A Burp Suite extension to apply the current Google Two-Tactor Authentication (2FA) code to relevant/selected requests.`,
    href: "//github.com/aress31/googleauthenticator",
    id: 11,
    imgAlt: "GoogleAuthenticator (Burp Suite Extension)",
    imgSrc: GoogleAuth,
    title: "GoogleAuthenticator (Burp Suite Extension)",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["Tools"],
    content: ``,
    date: "04 March 2022",
    excerpt: `A Burp Suite extension that copies the selected request(s) to the clipboard as PowerShell object assignments.`,
    href: "//github.com/aress31/copy-as-powershell-requests",
    id: 12,
    imgAlt: "copy-as-powershell-requests (Burp Suite Extension)",
    imgSrc: PowerShell,
    title: "copy-as-powershell-requests (Burp Suite Extension)",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["Tools"],
    content: ``,
    date: "04 March 2022",
    excerpt: `A highly modular Python script designed to parse the results from popular tools used by security professionals into Excel workbooks to facilitate the reporting phase of penetration tests.`,
    href: "//github.com/aress31/pentest2xlsx",
    id: 13,
    imgAlt: "pentest2xlsx",
    imgSrc: Excel,
    title: "pentest2xlsx",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["Tools"],
    content: ``,
    date: "04 March 2022",
    excerpt: `Allows users to perform various SMB-related attacks across multiple Active Directory (AD) domains or hosts. Supported features are listed under the Features section.`,
    href: "//github.com/aress31/smbaudit",
    id: 14,
    imgAlt: "SMBAudit",
    imgSrc: SMB,
    title: "SMBAudit",
  },
  {
    author: {
      imgSrc: AlexandreTeyar,
      name: "Alexandre Teyar",
    },
    categories: ["Tools"],
    content: ``,
    date: "04 March 2022",
    excerpt: `Helpers (custom queries) for BloodHound.`,
    href: "//github.com/aress31/bloodhound-helpers",
    id: 14,
    imgAlt: "bloodhound-helpers",
    imgSrc: BloodHound,
    title: "bloodhound-helpers",
  },
];
