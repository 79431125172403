import {
  BusinessCenter as BusinessCenterIcon,
  Code as CodeIcon,
  EmojiEmotions as EmojiEmotionsIcon,
  Public as PublicIcon,
  Science as ScienceIcon,
} from "@mui/icons-material";

export default [
  {
    content: `Our core strength as an organisation is our team, who are all experts 
    in their field that have been carefully chosen from the best of the best. Each 
    has more than a decade's worth of experience in the cyber industry and experience 
    working on highly classified and secure projects, not just in the UK but around 
    the world.`,
    icon: BusinessCenterIcon,
    title: "Extensive Industry Experience",
  },
  {
    content: `We are passionate about research and development and making a positive 
    impact on the cyber security industry. Many members of our team have given presentations 
    and talks at various industry conferences and dedicate their free time to reverse 
    engineering, researching zero-days and identifying security bugs in popular software.`,
    icon: ScienceIcon,
    title: "Focus on Research and Development",
  },
  {
    content: `All members of our team are comfortable working in the global marketplace, 
    can speak and understand multiple languages and have worked in other parts of the 
    world besides the UK, including the United States and Canada, the Middle East and 
    Asia. This level of global experience means that we are fully at ease with all 
    traditions and cultures.`,
    icon: PublicIcon,
    title: "International Exposure",
  },
  {
    content: `Over the years, our team have received commendation and recommendations 
    from our clients, thanks to the world-class service they provide. This is because 
    we listen carefully to the client and ensure that we not only meet their 
    expectations but exceed them.`,
    icon: EmojiEmotionsIcon,
    title: "Proven Track Record",
  },
  {
    content: `Each of the experts on our team is comfortable working in all areas of 
    IT and computing. Our skills are not limited to just the cyber security sphere. 
    We have robust and practical experience working in areas like software/web development, 
    network/system administration and other crucial fields. This technical proficiency allows 
    us to understand the specific needs of our clients and deliver accordingly.`,
    icon: CodeIcon,
    title: "Robust and Reliable IT Skills",
  },
];
