import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { seo } from "../models";

const CustomHelmet = ({ theme }) => {
  const { pathname } = useLocation();
  const { meta, title } = seo[pathname] || seo["not-found"];

  const baseMeta = [
    { content: "website", property: "og:type" },
    { content: "Aegis Cyber Limited", property: "og:site_name" },
    { content: "2022-03-04T13:30:00+00:00", property: "og:updated_time" },
    {
      content: theme.palette.primary.main,
      name: "theme-color",
    },
    { content: "en_GB", property: "og:locale" },
  ];

  const imageMeta = [
    {
      content: "https://www.aegiscyber.co.uk/logo512.png",
      property: "og:image:url",
    },
    {
      content: "https://www.aegiscyber.co.uk/logo512.png",
      property: "og:image:secure_url",
    },
    {
      content: "image/png",
      property: "og:image:type",
    },
    {
      content: "512",
      property: "og:image:width",
    },
    {
      content: "512",
      property: "og:image:height",
    },
    {
      content: "Aegis Cyber logo",
      property: "og:image:alt",
    },
  ];

  const allMetaTags = [...baseMeta, ...imageMeta, ...meta];

  return (
    <Helmet
      defaultTitle="Cyber Security, Social Engineering, Compliance"
      meta={allMetaTags}
      title={title}
      titleTemplate="%s | Aegis Cyber"
    />
  );
};

CustomHelmet.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default CustomHelmet;
