export default {
  dark: [
    { background: "#30424e", foreground: "#4caf50" },
    { background: "#28415f", foreground: "#03a9f4" },
    { background: "#383458", foreground: "#9c27b0" },
    { background: "#41374c", foreground: "#f44336" },
    { background: "#333659", foreground: "#673ab7" },
    { background: "#2e3858", foreground: "#3f51b5" },
  ],
  light: [
    { background: "#edf7ed", foreground: "#4caf50" },
    { background: "#e5f6fe", foreground: "#03a9f4" },
    { background: "#f5e9f7", foreground: "#9c27b0" },
    { background: "#feeceb", foreground: "#f44336" },
    { background: "#f0ebf8", foreground: "#673ab7" },
    { background: "#ebedf7", foreground: "#3f51b5" },
  ],
};
