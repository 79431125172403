export default {
  "/": {
    meta: [
      {
        content:
          "We are a cyber security services and solutions provider working with organisations worldwide to protect their digital assets, brand, and reputation against the ever-increasing threats of cyber-attacks. We focus on major areas of cyber security ranging from offense to defence, as well as compliance and training. Our purpose is to guarantee client satisfaction by providing top-tier services at the best value.",
        name: "description",
      },
      {
        content:
          "We are a cyber security services and solutions provider working with organisations worldwide to protect their digital assets, brand, and reputation against the ever-increasing threats of cyber-attacks. We focus on major areas of cyber security ranging from offense to defence, as well as compliance and training. Our purpose is to guarantee client satisfaction by providing top-tier services at the best value.",
        property: "og:description",
      },
      {
        content: "Home",
        property: "og:title",
      },
      {
        content: "https://www.aegiscyber.co.uk/",
        property: "og:url",
      },
    ],
    title: "Home",
  },
  "/about": {
    meta: [
      {
        content:
          "We are a United Kingdom based consultancy specialised in cyber risk management and compliance providing organisations worldwide with first-class services from our handpicked team of accredited experts at the best value.",
        name: "description",
      },
      {
        content:
          "We are a United Kingdom based consultancy specialised in cyber risk management and compliance providing organisations worldwide with first-class services from our handpicked team of accredited experts at the best value.",
        property: "og:description",
      },
      { content: "About", property: "og:title" },
      {
        content: "https://www.aegiscyber.co.uk/about",
        property: "og:url",
      },
    ],
    title: "About",
  },
  "/contact": {
    meta: [
      {
        content:
          "Contact us today and we will immediately get you started in the right direction. No hard sell, just useful advice from our team of friendly experts.",
        name: "description",
      },
      {
        content:
          "Contact us today and we will immediately get you started in the right direction. No hard sell, just useful advice from our team of friendly experts.",
        property: "og:description",
      },
      { content: "Contact", property: "og:title" },
      {
        content: "https://www.aegiscyber.co.uk/contact",
        property: "og:url",
      },
    ],
    title: "Contact",
  },
  "/legal/privacy-policy": {
    meta: [
      {
        content:
          "Our Privacy Policy is an extension of our commitment to combine the finest quality products and services with the highest level of integrity in dealing with our customers. The Policy guides how we collect, store, and use information about customers and prospects, and it will be periodically assessed against new technologies.",
        name: "description",
      },
      {
        content:
          "Our Privacy Policy is an extension of our commitment to combine the finest quality products and services with the highest level of integrity in dealing with our customers. The Policy guides how we collect, store, and use information about customers and prospects, and it will be periodically assessed against new technologies.",
        property: "og:description",
      },
      { content: "Privacy Policy", property: "og:title" },
      {
        content: "https://www.aegiscyber.co.uk/privacy-policy",
        property: "og:url",
      },
    ],
    title: "Privacy Policy",
  },
  "/legal/terms-and-conditions": {
    meta: [
      {
        content:
          "Our Terms and Conditions. Want more info? Feel free to get in touch.",
        name: "description",
      },
      {
        content:
          "Our Terms and Conditions. Want more info? Feel free to get in touch.",
        property: "og:description",
      },
      { content: "Terms and Conditions", property: "og:title" },
      {
        content: "https://www.aegiscyber.co.uk/terms-and-conditions",
        property: "og:url",
      },
    ],
    title: "Terms and Conditions",
  },
  "/resources/blog": {
    meta: [
      {
        content:
          "Our blog empowers information security professionals to achieve new heights through expert insight that addresses hot topics, trends and challenges and defines best practices.",
        name: "description",
      },
      {
        content:
          "Our blog empowers information security professionals to achieve new heights through expert insight that addresses hot topics, trends and challenges and defines best practices.",
        property: "og:description",
      },
      { content: "Blog", property: "og:title" },
      {
        content: "https://www.aegiscyber.co.uk/resources/blog",
        property: "og:url",
      },
    ],
    title: "Blog",
  },
  "/resources/careers": {
    meta: [
      {
        content:
          "Apply to our open positions to join the dynamic and friendly Aegis Cyber team and provide first-class services to our valued clients.",
        name: "description",
      },
      {
        content:
          "Apply to our open positions to join the dynamic and friendly Aegis Cyber team and provide first-class services to our valued clients.",
        property: "og:description",
      },
      { content: "Careers", property: "og:title" },
      {
        content: "https://www.aegiscyber.co.uk/resources/careers",
        property: "og:url",
      },
    ],
    title: "Careers",
  },
  "/services/compliance": {
    meta: [
      {
        content:
          "Do you need compliance services? Find out more about our experienced, well-supported compliance services.",
        name: "description",
      },
      {
        content:
          "Do you need compliance services? Find out more about our experienced, well-supported compliance services.",
        property: "og:description",
      },
      { content: "Compliance Services", property: "og:title" },
      {
        content: "https://www.aegiscyber.co.uk/services/compliance",
        property: "og:url",
      },
    ],
    title: "Compliance Services",
  },
  "/services/penetration-testing": {
    meta: [
      {
        content:
          "Do you need penetration testing services? Find out more about our experienced penetration testing services carried out by our team to assess the effectiveness of your security controls.",
        name: "description",
      },
      {
        content:
          "Do you need penetration testing services? Find out more about our experienced penetration testing services carried out by our team to assess the effectiveness of your security controls.",
        property: "og:description",
      },
      {
        content: "Penetration Testing Services",
        property: "og:title",
      },
      {
        content: "https://www.aegiscyber.co.uk/services/penetration-testing",
        property: "og:url",
      },
    ],
    title: "Penetration Testing Services",
  },
  // 'coming-soon': {
  //   Meta: [
  //     {
  //       Content:
  //         'We are working on this content to deliver the best quality, if you would like to get more information about the content to come, drop us a message and we will be happy to tell you more.',
  //       Name: 'description',
  //     },
  //     {
  //       Content:
  //         'We are working on this content to deliver the best quality, if you would like to get more information about the content to come, drop us a message and we will be happy to tell you more.',
  //       Property: 'og:description',
  //     },
  //     { content: 'Coming Soon', property: 'og:title' },
  //     {
  //       Content: 'https://www.aegiscyber.co.uk/resources/blog',
  //       Property: 'og:url',
  //     },
  //   ],
  //   Title: 'Coming Soon',
  // },
  "not-found": {
    meta: [
      {
        content: "This page does not currently exist.",
        name: "description",
      },
      {
        content: "This page does not currently exist.",
        property: "og:description",
      },
      { content: "Not Found", property: "og:title" },
      {
        content: "https://www.aegiscyber.co.uk/404",
        property: "og:url",
      },
    ],
    title: "Not Found",
  },
};
