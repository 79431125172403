import {
  AccountBalance as AccountBalanceIcon,
  CreditCard as CreditCardIcon,
  Gavel as GavelIcon,
  Policy as PolicyIcon,
} from "@mui/icons-material";

export default {
  FAQ: [
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question: "Do you follow a specific testing methodology?",
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question: "How much does a Penetration Test cost?",
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question: "How often should a Penetration Test be conducted?",
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question: "Are your consultants certified?",
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question: "What is a Penetration Test?",
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question:
        'What is the difference between an "External" and an "Internal" Penetration Test?',
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question:
        "What is the difference between a Penetration Test and a Vulnerability Scan?",
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question: "What is the final deliverable?",
    },
  ],
  children: [
    {
      description: `
[Cyber Essentials](//www.cyberessentials.ncsc.gov.uk/) is a cyber security standard introduced by the UK government that aims to provide organisations of all sizes with basic, cost-effective protection against the most common Internet-based threats.

Aegis Cyber is a certifying body for the Cyber Essentials programme, meaning that we have the legal authority to conduct an assessment of your organisation, report the outcome to the accreditation body, and ultimately issue the certificate upon successful assessment.
      `,
      icon: AccountBalanceIcon,
      id: "cyber-essentials",
      subheader:
        "Cyber Essentials has been developed by the UK Government, in conjunction with industry to provide clear guidelines of the basic controls that organisations should implement to protect against the most prevalent threats from the internet.",
      title: "Cyber Essentials",
    },
    {
      description: `
The [General Data Protection Regulation (GDPR) (Regulation (EU) 2016/679)](//gdpr-info.eu/) is a regulation by which the European Parliament, the European Council, and the European Commission intend to strengthen and unify data protection for individuals within the European Union (EU). It also addresses the export of personal data outside the EU. The primary objectives of GDPR are to give citizens back the control of their personal data and to simplify the regulatory environment for international businesses by unifying the regulation within the EU.

Aegis Cyber's in-depth expertise and years' of experience in designing and implementing data security strategies mean we are ideally placed to help you deliver your GDPR compliance program.
      `,
      icon: GavelIcon,
      id: "gdpr",
      subheader:
        "Comply with the GDPR requirements for assessing privacy-sensitive applications and critical infrastructure.",
      title: "GDPR",
    },
    {
      description: `
The [Payment Card Industry Data Security Standard (PCI DSS)](//www.pcisecuritystandards.org/) is an information security standard for organizations that handle branded credit cards from major card schemes. The PCI Standard is mandated by the card brands but administered by the Payment Card Industry Security Standards Council (PCI SSC).

Aegis Cyber is qualified by the PCI Security Standards Council (PCI SSC) as an assessor for PCI DSS, also known as PCI Qualified Security Assessors (PCI QSAs). With our PCI QSA status, we have the legal authority to assist merchants and service providers with PCI compliance, including the Self-Assessment Questionnaire (SAQ), Report on Compliance (ROC), and Attestation of Compliance (AOC).
      `,
      icon: CreditCardIcon,
      id: "pci-dss",
      subheader:
        "PCI DSS requires that penetration testing is performed at least annually, or after significant changes are made to the infrastructure, applications or systems that store, process or transmit sensitive cardholder data.",
      title: "PCI DSS",
    },
  ],
  description: `
With organisations facing increased risks surrounding the storage and handling of personal data, and the financial penalties for breaches becoming more significant, there has never been a more important time to ensure you meet the highest possible standards of data governance and regulatory compliance.

Our highly qualified and experienced team will guide you through your compliance obligations—technological, procedural, and cultural—to help you adhere to all the necessary rules, recommendations, and regulations. Taking a proactive attitude towards compliance will be viewed favorably by all stakeholders and paves the way to successfully mitigating commercial and reputational risks.
  `,
  href: "/services/compliance",
  icon: PolicyIcon,
  id: "compliance",
  subheader: "Adhering to industry standards.",
  title: "Compliance",
};
