export default [
  {
    content: "was the average cost of a data breach as of 2020.",
    prefix: "$",
    source: "- IBM",
    suffix: " millions",
    title: "3.86",
  },
  {
    content:
      "records were exposed from data breaches in the first half of 2020.",
    prefix: "",
    source: "- RiskBased",
    suffix: " billions",
    title: 36,
  },
  {
    content: "of breaches were financially motivated in 2020.",
    prefix: "",
    source: "- Verizon",
    suffix: "%",
    title: 86,
  },
];
