import {
  GitHub as GitHubIcon,
  LinkedIn as LinkedInIcon,
} from "@mui/icons-material";

export default [
  {
    ariaLabel: "GitHub",
    href: "//github.com/aegiscyber",
    icon: GitHubIcon,
  },
  {
    ariaLabel: "LinkedIn",
    href: "//www.linkedin.com/company/aegiscyber",
    icon: LinkedInIcon,
  },
];
