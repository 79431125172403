export default {
  background: `
**Please read these Terms and Conditions carefully and ensure that you understand them before using Our Site.**

These Terms and Conditions, together with any other documents referred to herein, set out the terms of use governing your use of this website, [](/ "Our Site") ("Our Site"). It is recommended that you print a copy of these Terms and Conditions for your future reference.

Your agreement to comply with these Terms and Conditions is indicated by your use of Our Site. If you do not agree to these Terms and Conditions, you must stop using Our Site immediately.

The following document[s] also apply to your use of Our Site:

- Our [Privacy Policy](/legal/privacy-policy).
  `,
  lastEdit: "25 Feb, 2022",
  notice: "Terms and Conditions",
  questions: `
# 1. How to contact us

To contact us, please email us at [support@aegiscyber.co.uk](mailto:support@aegiscyber.co.uk).

# 2. Access to Our Site

Access to Our Site is free of charge.

It is your responsibility to make the arrangements necessary in order to access Our Site.

Access to Our Site is provided on an "as is" and on an "as available" basis. We may suspend or discontinue Our Site (or any part of it) at any time. We do not guarantee that Our Site will always be available or that access to it will be uninterrupted. If we suspend or discontinue Our Site (or any part of it), we will try to give you reasonable notice of the suspension or discontinuation.

# 3. Changes to Our Site

We may alter and update Our Site (or any part of it) at any time. If we make any significant alterations to Our Site (or any part of it), we will try to give you reasonable notice of the alterations.

# 4. Changes to these Terms and Conditions

We may alter these Terms and Conditions at any time. If we do so, details of the changes will be highlighted at the top of this page. As explained above, your use of Our Site constitutes your acceptance of these Terms and Conditions. Consequently, any changes made to these Terms and Conditions will apply to your use of Our Site the first time you use it after the changes have been implemented. You are therefore advised to check this page every time you use Our Site.

If any part of the current version of these Terms and Conditions conflicts with any previous version(s), the current version shall prevail unless we explicitly state otherwise.

# 5. How you may use Our Site and content (Intellectual Property rights)

All content included on Our Site and the copyright and other intellectual property rights in that content belongs to or has been licensed by us, unless specifically labelled otherwise. All content is protected by applicable United Kingdom and international intellectual property laws and treaties.

You may access, view, and use Our Site in a web browser (including any web browsing capability built into other types of software or app) and you may download Our Site (or any part of it) for caching (this usually occurs automatically).

You may print one copy and download extracts of any page(s) from Our Site for personal use only.

You may not modify the printed copies or downloaded extracts in any way. Images, video, audio, or any other content downloaded from Our Site must not be used separately from accompanying text.

Our status as the owner and author of the content on Our Site (or that of identified licensors, as applicable) must always be acknowledged.

You may not use any content saved or downloaded from Our Site for commercial purposes without first obtaining a licence from us (or our licensors, as applicable). This does not prevent the normal access, viewing, and use of Our Site for general information purposes by business users or consumers.

# 6. Links to Our Site

You may link to any page on Our Site.

Links to Our Site must be fair and lawful. You must not take unfair advantage of Our reputation or attempt to damage Our reputation.

You must not link to Our Site in a manner that suggests any association with us (where there is none) or any endorsement or approval from us (where there is none).

Your link should not use any logos or trademarks displayed on Our Site without Our express written permission.

You must not frame or embed Our Site on another website without Our express written permission.

You may not link to Our Site from another website the main content of which is unlawful; obscene; offensive; inappropriate; dishonest; defamatory; threatening; racist, sexist, or otherwise discriminatory; that promotes violence, racial hatred, or terrorism; that infringes intellectual property rights; or that we deem to be otherwise objectionable.

# 7. Links to other sites

Links to other websites may be included on Our Site. Unless expressly stated, these sites are not under Our control. We accept no responsibility or liability for the content of third-party websites.

The inclusion of a link to another website on Our Site is for information purposes only and does not imply any endorsement of that website or of its owners, operators, or any other parties involved with it.

# 8. Disclaimers

Nothing on Our Site constitutes professional advice on which you should rely. It is provided for general information purposes only. Professional or specialist advice should always be obtained before taking any action.

We make reasonable efforts to ensure that the content on Our Site is complete, accurate, and up to date, but we make no warranties, representations, or guarantees (express or implied) that this will always be the case.

If you are a business user, we exclude all implied representations, warranties, conditions, and other terms that may apply to Our Site and content.

# 9. Our liability

Nothing in these Terms and Conditions excludes or restricts Our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be lawfully excluded or restricted.

If you are a business user (i.e. you are using Our Site in the course of business or for commercial purposes), to the fullest extent permissible by law, we accept no liability for any loss or damage, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising out of or in connection with the use of (or inability to use) Our Site or the use of or reliance upon any content included on Our Site.

If you are a business user, we accept no liability for loss of profit, sales, business, or revenue; loss of business opportunity, goodwill, or reputation; loss of anticipated savings; business interruption; or for any indirect or consequential loss or damage.

If you are a consumer, you agree that you will not use Our Site for any commercial or business purposes and that we shall have no liability to you for any business losses as set out above.

# 10. Viruses, malware, and security

We exercise reasonable skill and care to ensure that Our Site is secure and free from viruses and malware; however, we do not guarantee that this is the case.

You are responsible for protecting your hardware, software, data, and other material from viruses, malware, and other internet security risks.

You must not deliberately introduce viruses or other malware, or any other material which is malicious or technologically harmful either to or via Our Site.

You must not attempt to gain unauthorised access to any part of Our Site, the server on which Our Site is stored, or any other server, computer, or database connected to Our Site.

You must not attack Our Site by means of a denial-of-service attack, a distributed denial of service attack, or by any other means.

By breaching the provisions of Parts 13.3 to 13.5, you may be committing a criminal offence under the Computer Misuse Act 1990. Any and all such breaches will be reported to the relevant law enforcement authorities and we will cooperate fully with those authorities by disclosing your identity to them. Your right to use Our Site will cease immediately in the event of such a breach.

# 11. Acceptable usage of Our Site

You may only use Our Site in a lawful manner:

1. You must ensure that you comply fully with any and all local, national, or international laws and regulations that apply;
2. You must not use Our site in any way, or for any purpose, that is unlawful or fraudulent; and
3. You must not use Our Site to knowingly send, upload, or in any other way transmit data that contains any form of virus or other malware or any other code designed to adversely affect computer hardware, software, or data of any kind.

If you fail to comply with the provisions of this Section, you will be in breach of these Terms and Conditions. We may take one or more of the following actions in response:

1. Suspend or terminate your right to use Our Site;
2. Issue you with a written warning;
3. Take legal proceedings against you for reimbursement of any and all relevant costs on an indemnity basis resulting from your breach;
4. Take further legal action against you, as appropriate;
5. Disclose such information to law enforcement authorities as required or as we deem reasonably necessary; and/or
6. Any other actions which we deem reasonably appropriate (and lawful).

We hereby exclude any and all liability arising out of any actions that we may take (including, but not limited to those set out above in this Section) in response to your breach.

# 12. How we use your personal information

We will only use your personal information as set out in our [Privacy Policy](https:///legal/privacy-policy).

# 13. Communications from us

If we have your contact details, we may send you important notices by email from time to time. Such notices may relate to matters including, but not limited to, changes to Our Site or to these Terms and Conditions.

We will not send you marketing emails without your express consent. If you do consent to marketing, you may opt out at any time. All marketing emails from us include an unsubscribe link. If you opt out of emails from us, it may take up to 3 months for your request to take effect and you may continue to receive emails during that time.

For questions or complaints about communications from us, please contact us.

# 14. Law and jurisdiction

These Terms and Conditions, and the relationship between you and us (whether contractual or otherwise) shall be governed by, and construed in accordance with, English law.

If you are a consumer, you will benefit from any mandatory provisions of the law in your country of residence. Nothing in Part 1 of this Section takes away from or reduces your legal rights as a consumer.

If you are a consumer, any dispute, controversy, proceedings, or claim between you and us relating to these Terms and Conditions or to the relationship between you and us (whether contractual or otherwise) shall be subject to the jurisdiction of the courts of England, Wales, Scotland, or Northern Ireland, as determined by your residency.

If you are a business user, any dispute, controversy, proceedings, or claim between you and us relating to these Terms and Conditions or to the relationship between you and us (whether contractual or otherwise) shall be subject to the exclusive jurisdiction of the courts of England and Wales.
`,
};
