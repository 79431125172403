import {
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useColorScheme } from "@mui/material/styles";
import React from "react";

const DarkModeButton = () => {
  const { mode, setMode } = useColorScheme();
  const title = mode ? "Switch to light mode" : "Switch to dark mode";

  return (
    <IconButton
      color="inherit"
      size="small"
      sx={{
        borderColor: "divider",
        borderRadius: 2,
        borderStyle: "solid",
        borderWidth: 1,
      }}
      title={title}
      onClick={() => setMode(mode === "dark" ? "light" : "dark")}
    >
      {mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
    </IconButton>
  );
};

export default DarkModeButton;
