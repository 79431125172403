export default [
  {
    company: "Information Technology & Services, Sweden",
    content:
      "When you consider their competitive rates and flexible easy-going people, Aegis Cyber are a joy to work alongside. So much so we have made them our Penetration Testing partner and they now deliver a managed service for us.",
    name: "",
    role: "CEO",
  },
  {
    company: "Cyber Security Provider, UAE",
    content:
      "The work and interactions - with Alexandre Teyar in particular - were so impressive that we would not even consider tendering elsewhere at this point.",
    name: "",
    role: "Senior Manager",
  },
  {
    company: "Financial Technology, Luxembourg",
    content:
      "Aegis Cyber provided an excellent service. Their security service was tailored to meet our needs perfectly.",
    name: "",
    role: "CISO",
  },
];
