import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  styled,
  SvgIcon,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { uid } from "react-uid";

import { Divider as SvgDivider } from "../../assets/optimized-svgs";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const CardBlog = ({ data, ...props }) => (
  <Card
    {...props}
    sx={{
      borderRadius: 2,
      height: "100%",
    }}
    variant="outlined"
  >
    <CardActionArea
      href={data.href}
      {...(data.href.startsWith("//") && {
        component: "a",
        rel: "noopener noreferrer",
        target: "_blank",
      })}
      sx={{
        alignItems: "stretch",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <CardMedia
        alt={data.imgAlt}
        sx={{
          backgroundImage: `url(${data.imgSrc})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 256,
          position: "relative",
        }}
        variant="filter"
      >
        <SvgIcon
          inheritViewBox
          component={SvgDivider}
          id="background-default"
          sx={{
            bottom: 0,
            height: 32,
            left: 0,
            position: "absolute",
            width: "100%",
            zIndex: 0,
          }}
        />
        <Box
          component="ul"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            listStyle: "none",
            margin: 0,
            padding: 0,
            position: "absolute",
            right: 12,
            top: 12,
          }}
        >
          {data.categories.map((category) => (
            <ListItem key={uid(category)}>
              <Chip
                label={category}
                sx={{
                  bgcolor: "background.default",
                  fontWeight: 550,
                }}
                variant="outlined"
              />
            </ListItem>
          ))}
        </Box>
      </CardMedia>
      <CardContent
        sx={{
          flexGrow: 1,
        }}
      >
        <Typography gutterBottom variant="h6">
          {data.title}
        </Typography>
        <Typography sx={{ color: "text.secondary" }} variant="subtitle1">
          {data.excerpt}
        </Typography>
      </CardContent>
      <CardActions
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.divider}`,
          justifyContent: "space-between",
          padding: 2,
        })}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <Avatar
            alt={data.author.name}
            src={data.author.imgSrc}
            sx={{ mr: 1 }}
          />
          <Typography sx={{ color: "text.secondary" }}>
            {data.author.name}
          </Typography>
        </Box>
        <Typography sx={{ color: "text.secondary" }}>{data.date}</Typography>
      </CardActions>
    </CardActionArea>
  </Card>
);

CardBlog.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CardBlog;
