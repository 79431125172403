import {
  DeviceHub as DeviceHubIcon,
  Dns as DnsIcon,
  Lock as LockIcon,
  Smartphone as SmartphoneIcon,
  Web as WebIcon,
} from "@mui/icons-material";

export default {
  FAQ: [
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question: "Do you follow a specific testing methodology?",
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question: "How much does a Penetration Test cost?",
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question: "How often should a Penetration Test be conducted?",
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question: "Are your consultants certified?",
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question: "What is a Penetration Test?",
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question:
        'What is the difference between an "External" and an "Internal" Penetration Test?',
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question:
        "What is the difference between a Penetration Test and a Vulnerability Scan?",
    },
    {
      answer:
        "Mollit laborum adipisicing irure dolore eiusmod amet exercitation.",
      question: "What is the final deliverable?",
    },
  ],
  children: [
    {
      description: `
Aegis Cyber provides external and internal network infrastructure penetration testing.

Whether you would like to assess the resilience of your organisation's infrastructure from an internal viewpoint in order to simulate the kind of damages an disgrunted employee or a trusted insider could cause to your network or check the security of your permiter and the risk of getting compromised from the countless wider Internet.

Our experts posses a solid background in system administration and networking allowing them to quickly understand the specificities of our clients infrastructure as well as any specific requirements. We optimise our testing methodology on a per engagement basis in order to focus the allocated and resources on determining the potential attack surface and identifying the most likely and damaging attack vectors. We follow industry standards such as [PTES (Penetration Testing Execution Standard)](//www.pentest-standard.org/index.php/Main_Page) and [NIST (National Institute of Standards and Technology)](//www.nist.gov/) to deliver consistent reports.
      `,
      icon: DnsIcon,
      id: "infrastructure",
      subheader:
        "Protect your external and internal networks from threat actors.",
      title: "External and Internal Infrastructure",
    },
    {
      description: `
Aegis Cyber provides hardware and Internet of Things (IoT) penetration testing and have developed our own highly specialised in-house testing methodology which builds on the [OWASP Top 10 IoT](//owasp.org/www-project-internet-of-things/) industry standard.

The IoT market is growing at an exponential rate and is driven by technologies such as IPv6 and 5G. The market revenue is forecasted to reach £1.6 trillion by 2025 and security will play a key factor in the success or failure of public adoption of these new technologies. Recent stories of breaches have demonstrated that people homes are becoming a new target for Internet attackers due to the proliferation of insecure IoT devices. News showing attackers spying on people using smart CCTV or controlling their smart door lock seriously damages the reputation of product manufacturers.

Our experts are passionate about working with these new technologies and custom hardware, and have discovered 0-day vulnerabilities on IoT devices used worldwide by large corporations. So no matter the technical specification or custom hardware technology you would like to test and strengthen from a security perspective, at Aegis Cyber we are here to help.
      `,
      icon: DeviceHubIcon,
      id: "iot",
      subheader:
        "The security of your connected devices is vital, especially when sensitive data is being processed.",
      title: "Hardware and Internet of Things (IoT)",
    },
    {
      description: `
Aegis Cyber provides mobile application penetration testing for both Android and iOS platforms.

Our experts are pioneers in this area, having contributed heavily to the security of the iOS and Android ecosystems through the publication of technical papers and the discovery of bugs and new attack techniques for these platforms. They possess deep knowledge of the entire ecosystem and architecture, along with industry-standard JAVA/Kotlin and Swift/Objective-C development skills, which enable them to frequently uncover critical bugs and vulnerabilities that could harm our clients' reputation if left undetected.

Furthermore, at Aegis Cyber, we adhere to the latest iteration of the [OWASP Top 10 Mobile](//owasp.org/www-project-mobile-top-10/) industry standards to provide consistent and organised reporting. We have also developed our own in-house testing methodology, which employs a hybrid approach to leverage the advantages of both manual and automated testing, ensuring maximum coverage of tested applications.
      `,
      icon: SmartphoneIcon,
      id: "mobile-application",
      subheader:
        "Mobile applications are an ideal target for threat actors. Make sure yours are secure.",
      title: "Mobile Application",
    },
    {
      description: `
Aegis Cyber provides web application and web services penetration testing.

Modern web applications are becoming increasingly complex and are interacting with more types of backend servers than ever. This complexification of web application architectures introduced a greater risk of the entire organisation being compromised in the event that a web application is compromised. Despite these concerns, the security of web applications and services is very often overlooked and recent breaches have shown that attackers hone in on them as they often contain a variety of vulnerabilities that could provide the attackers with a foothold in the infrastructure of the organisation.

Our experts have conducted hundreds of different web application testing projects, working on a plethora of web technologies. They remain up-to-date with industry standard testing methodologies such as [OWASP Top 10](//owasp.org/www-project-top-ten/) and contribute to the cyber security community with the creation of industry recognised tools designed to streamline the process of testing web applications and services. This experience as well as the familiarity of our experts with the latest web development trends and frameworks ensures maximum coverage and identification of the most critical vulnerabilities present in your web applications and services.

So whether you are looking for authenticated or unauthenticated testing, with a blackbox or whitebox testing approach, at Aegis Cyber we are ready to listen and understand your needs so that we can provide the best assistance possible in improving and maintaining the security of your web-based assets.
      `,
      icon: WebIcon,
      id: "web-application",
      subheader:
        "Protect applications such as websites, third-party software and e-commerce platforms from threat actors.",
      title: "Web Application and Services",
    },
  ],
  description: `
Whether you require an objective review of your information security posture, or need to test the effectiveness of your infrastructure and applications, our assurance services will meet your needs.

From consultancy through to penetration testing, social engineering, and red teaming, our consultants are highly experienced in all evaluation methodologies and are well-qualified to support you.
  `,
  href: "/services/penetration-testing",
  icon: LockIcon,
  id: "penetration-testing",
  subheader: "Discovering and exploiting weaknesses within your organisation.",
  title: "Penetration Testing | Vulnerability Assessment",
};
