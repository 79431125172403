import {
  Box,
  Grid2,
  IconButton,
  Link,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import merge from "deepmerge";
import PropTypes from "prop-types";
import React from "react";
import { uid } from "react-uid";

import { AegisIcon } from "../../assets";
import { publicRoutes } from "../../configs/router/publicRoutes";
import { contacts, socialMedias } from "../../models";
import SocialMediaLinks from "../SocialMediaLinks";

const StyledLink = styled(Link)(({ theme }) => ({
  "&.active, &:hover": {
    color: theme.palette.text.primary,
    fontWeight: 425,
  },
  
  color: theme.palette.text.secondary,
  textDecoration: "none",
}));

const getFilteredData = () => {
  const companyRoutes = publicRoutes.filter(
    (route) =>
      route.id &&
      route.id !== "Home" &&
      route.id !== "Services" &&
      !route.children,
  );
  const resourcesRoute = publicRoutes.find((route) => route.id === "Resources");
  const servicesRoute = publicRoutes.find((route) => route.id === "Services");

  const londonContact = contacts.find(
    (item) => item.label === "London, UK (HQ)",
  );

  const filteredData = [
    {
      children: servicesRoute.patterns.map(({ path, ...childRoute }) => ({
        ...childRoute,
        path: `/services/${path}`,
      })),
      title: "Services",
    },
    {
      children: merge(
        companyRoutes,
        resourcesRoute.children.map(({ path, ...childRoute }) => ({
          ...childRoute,
          path: `/resources/${path}`,
        })),
      ).sort((a, b) => a.id.localeCompare(b.id)),
      title: "Company",
    },
    {
      children: londonContact.children,
      title: "Contact",
    },
  ];

  return filteredData;
};

const FooterLink = ({ item }) => (
  <Stack alignItems="center" direction="row" spacing={1}>
    {item.icon && <item.icon sx={{ mr: 1 }} />}
    <StyledLink
      href={item.href || item.path}
      rel={item.type === "Address" ? "noopener noreferrer" : undefined}
      sx={{
        fontWeight: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "pre-line",
      }}
      target={item.type === "Address" ? "_blank" : undefined}
      variant="subtitle1"
    >
      {item.id || item.label}
    </StyledLink>
  </Stack>
);

FooterLink.propTypes = {
  item: PropTypes.shape({
    href: PropTypes.string,
    icon: PropTypes.elementType,
    id: PropTypes.string,
    label: PropTypes.string,
    path: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

const LogoSection = () => (
  <Grid2 size={{ md: 3, sm: 4, xs: 12 }}>
    <IconButton
      aria-label="go to home"
      component={Link}
      href="/"
      id="logo-footer"
    >
      <AegisIcon sx={{ fontSize: { md: 104, sm: 96, xs: 88 } }} />
    </IconButton>
  </Grid2>
);

const Column = ({ data }) => (
  <Stack direction="column" spacing={1}>
    <Typography sx={{ fontSize: 18 }} variant="subtitle1">
      {data.title}
    </Typography>
    {data.children.map((item) => (
      <FooterLink key={uid(item)} item={item} />
    ))}
  </Stack>
);

Column.propTypes = {
  data: PropTypes.shape({
    children: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

const CopyrightSection = () => (
  <Box
    sx={(theme) => ({
      alignItems: "center",
      borderTop: `1px solid ${theme.palette.divider}`,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      py: 0.5,
    })}
  >
    <SocialMediaLinks socialMedias={socialMedias} />
    <Typography sx={{ color: "text.secondary" }} variant="subtitle2">
      © {new Date().getFullYear()} <strong>Aegis Cyber Limited</strong>. All
      rights reserved.
    </Typography>
    <Stack alignItems="center" direction="row" spacing={1}>
      {[
        { link: "/legal/privacy-policy", title: "Privacy Policy" },
        {
          link: "/legal/terms-and-conditions",
          title: "Terms and Conditions",
        },
      ].map((item) => (
        <Link
          key={uid(item)}
          href={item.link}
          sx={{
            color: "text.secondary",
            fontWeight: "normal",
            textDecoration: "none",
          }}
          variant="subtitle2"
        >
          {item.title}
        </Link>
      ))}
    </Stack>
  </Box>
);

const Footer = () => {
  const filteredData = getFilteredData();

  return (
    <>
      <Grid2 container component="nav" spacing={2} sx={{ pb: 2 }}>
        <LogoSection />
        {filteredData.map((colData) => (
          <Grid2 key={uid(colData)} size={{ md: 3, sm: 4, xs: 12 }}>
            <Column data={colData} />
          </Grid2>
        ))}
      </Grid2>
      <CopyrightSection />
    </>
  );
};

export default Footer;
