import { nopeResolver } from "@hookform/resolvers/nope";
import { ArrowRightAlt as ArrowRightAltIcon } from "@mui/icons-material";
import { Button, Grid2, TextField } from "@mui/material";
import Nope from "nope-validator";
import { useSnackbar } from "notistack";
import React from "react";
import ReactGA from "react-ga4";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

const FormQuote = () => {
  const { pathname } = useLocation();

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      "form-name": "get-quote",
      page: pathname,
    },
    mode: "onChange",
    resolver: nopeResolver(
      Nope.object().shape({
        email: Nope.string().email().required(),
        message: Nope.string().min(32).max(384).required(),
        name: Nope.string().required(),
        organisation: Nope.string().required(),
        tel: Nope.string().required(),
      }),
    ),
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (formData) => {
    ReactGA.event({
      action: "submit",
      category: "form",
      label: "get-quote",
    });

    // TODO: Required to bypass the SW cache for Netlify form compatibility
    fetch("/?t=" + Math.floor(Date.now() / 1000), {
      body: new URLSearchParams(formData).toString(),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "POST",
    })
      .then(() =>
        enqueueSnackbar("Form successfully submitted!", {
          variant: "success",
        }),
      )
      .catch(() =>
        enqueueSnackbar(
          "Something went wrong and your message could not be sent.",
          {
            variant: "error",
          },
        ),
      );
  };

  return (
    <Grid2
      container
      noValidate
      component="form"
      justifyContent="center"
      name="get-quote"
      spacing={{ md: 2, xs: 1 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid2 size={{ md: 6, xs: 12 }}>
        <TextField
          {...register("name")}
          fullWidth
          required
          error={Boolean(formState.errors.name)}
          helperText={
            Boolean(formState.errors.name) && formState.errors.name.message
          }
          id="name"
          label="Full name"
          name="name"
          type="text"
        />
      </Grid2>
      <Grid2 size={{ md: 6, xs: 12 }}>
        <TextField
          {...register("organisation")}
          fullWidth
          required
          autoComplete="organization"
          error={Boolean(formState.errors.organisation)}
          helperText={
            Boolean(formState.errors.organisation) &&
            formState.errors.organisation.message
          }
          id="organisation"
          label="Company name"
          name="organisation"
          type="text"
        />
      </Grid2>
      <Grid2 size={{ md: 6, xs: 12 }}>
        <TextField
          {...register("email")}
          fullWidth
          required
          autoComplete="email"
          error={Boolean(formState.errors.email)}
          helperText={
            Boolean(formState.errors.email) && formState.errors.email.message
          }
          id="email"
          label="Email address"
          name="email"
          type="email"
        />
      </Grid2>
      <Grid2 size={{ md: 6, xs: 12 }}>
        <TextField
          {...register("tel")}
          fullWidth
          required
          autoComplete="tel"
          error={Boolean(formState.errors.tel)}
          helperText={
            Boolean(formState.errors.tel) && formState.errors.tel.message
          }
          id="tel"
          label="Phone number"
          name="tel"
          type="tel"
        />
      </Grid2>
      <Grid2 size={12}>
        <TextField
          {...register("message")}
          fullWidth
          multiline
          required
          autoComplete="text"
          error={Boolean(formState.errors.message)}
          helperText={
            Boolean(formState.errors.message) &&
            formState.errors.message.message
          }
          id="message"
          label="Your message/question"
          name="message"
          rows={6}
          type="text"
          variant="outlined"
        />
      </Grid2>
      <Grid2 size={12} textAlign="center">
        <Button
          disabled={!formState.isValid || formState.isSubmitting}
          endIcon={<ArrowRightAltIcon />}
          size="medium"
          type="submit"
        >
          Get a Free Quote
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default FormQuote;
