import { Grid2 } from "@mui/material";
import merge from "deepmerge";
import PropTypes from "prop-types";
import React from "react";
import { uid } from "react-uid";

const HeroComponent = ({ children, bgImages }) => (
  <>
    {children}
    <Grid2 container spacing={2}>
      {bgImages.map((item, index) => (
        <Grid2
          key={uid(item)}
          size={[0, 3].includes(index) ? 4 : 8}
          sx={item.gridSx}
        >
          <img
            alt={item.title}
            src={item.imgSrc}
            srcSet={item.imgSrc}
            style={merge(
              {
                borderRadius: "8px",
                maxHeight: "25vh",
                objectFit: "cover",
              },
              item.style,
            )}
          />
        </Grid2>
      ))}
    </Grid2>
  </>
);

HeroComponent.propTypes = {
  bgImages: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default HeroComponent;
