import { alpha } from "@mui/material";
import { red } from "@mui/material/colors";
import merge from "deepmerge";
import PropTypes from "prop-types";
import React from "react";
import { NavLink as RouterLink } from "react-router-dom";

const LinkBehavior = React.forwardRef(({ href, end, ...props }, ref) => (
  <RouterLink
    ref={ref}
    data-testid="custom-link"
    end={end}
    to={href}
    {...props}
  />
));

LinkBehavior.displayName = "LinkBehavior";
LinkBehavior.propTypes = {
  end: PropTypes.bool,
  href: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
};

export const getDesignTokens = () => ({
  colorSchemes: {
    dark: {
      palette: {
        background: { darker: "#080808" },
        primary: { main: red[700] },
        contrastThreshold: 4.5,
        tonalOffset: 0.2,
      },
    },
    light: {
      palette: {
        background: { darker: "#f7faff" },
        primary: { main: red[400] },
        contrastThreshold: 4.5,
        tonalOffset: 0.1,
      },
    },
  },
});

const linkBehaviorOverride = {
  defaultProps: {
    component: LinkBehavior,
  },
};

export const getThemedComponents = () => ({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", size: "large" },
          style: { fontWeight: "bolder" },
        },
        {
          props: { variant: "contained", size: "medium" },
          style: { fontWeight: "bold" },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: ({ theme }) => ({
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
            ...theme.applyStyles("dark", {
              borderColor: theme.palette.primary.light,
              color: theme.palette.primary.light,
            }),
          }),
        },
        {
          props: { variant: "text", color: "primary" },
          style: ({ theme }) => ({
            color: theme.palette.primary.dark,
            ...theme.applyStyles("dark", {
              color: theme.palette.primary.light,
            }),
            fontWeight: "bold",
          }),
        },
      ],
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`:not([id^='logo']).active`]: {
            backgroundColor: `${alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)}`,
          },
        }),
      },
    },
    MuiCardMedia: {
      variants: [
        {
          props: { variant: "filter" },
          // XXX: Adjust the brightness filter for the SVG divider
          style: ({ theme }) =>
            theme.applyStyles("dark", {
              filter: "brightness(0.75)",
              "& svg": { filter: "brightness(1.25)" },
            }),
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: "filter" },
          style: ({ theme }) =>
            theme.applyStyles("dark", { filter: "grayscale(0.25)" }),
        },
      ],
    },
    // XXX: Track https://github.com/mui-org/material-ui/issues/28354
    MuiLink: merge(linkBehaviorOverride, {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.dark,
          ...theme.applyStyles("dark", {
            color: theme.palette.primary.light,
          }),
          fontWeight: 550,
        }),
      },
    }),
    MuiMenuItem: linkBehaviorOverride,
    MuiSvgIcon: {
      variants: [
        {
          props: { variant: "filter" },
          style: ({ theme }) =>
            theme.applyStyles("dark", { filter: "grayscale(0.25)" }),
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: "p",
          body2: "p",
          h1: "h1",
          h2: "h1",
          h3: "h2",
          h4: "h3",
          h5: "h5",
          h6: "p",
          subtitle1: "p",
          subtitle2: "p",
        },
      },
      variants: [
        {
          props: { variant: "h4", align: "center" },
          style: { width: "100%", fontWeight: "bold" },
        },
        {
          props: { variant: "h4", gutterBottom: true },
          style: ({ theme }) => ({
            ":after": {
              background: `none repeat scroll 0 0 ${theme.palette.primary.main}`,
              bottom: -8,
              content: '""',
              display: "block",
              height: 3,
              left: "50%",
              position: "absolute",
              transform: "translate(-50%)",
              width: 96,
            },
            display: "inline-block",
            position: "relative",

            // TODO: Replace with text-decoration once fixed-width support is available.
            // textDecoration: "underline",
            // textDecorationThickness: 3,
            // textDecorationColor: theme.palette.primary.main,
            // textUnderlineOffset: 16,

            marginBottom: 32,
          }),
        },
        {
          props: { variant: "tag" },
          style: ({ theme }) => ({
            color: theme.palette.primary.dark,
            ...theme.applyStyles("dark", {
              color: theme.palette.primary.light,
            }),
            display: "block",
            textTransform: "uppercase",
            fontWeight: 550,
          }),
        },
      ],
    },
  },
});
